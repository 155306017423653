<template>
	<div class="full-height size-px-14 position-relative">
		<div class="header">
			<div
				class="top top_mafi "
			><!--trans-->
				<div class="util shadow">
					<button
						class="arrow_white"
						@click="goBack"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit ml-15">{{ program.title }}</h2>
				<div
					class="util size-px-16"
					style="margin-left: auto;"
				>
					<span
						v-show="mode == 'edit'"
						class="color-white"
						@click="postSave"
					>{{ $language.common.complete }} <!-- 완료 --></span>
					<span
						v-show="mode == 'empty'"
						class="color-white"
						@click="mode = 'edit'"
					>{{ $language.common.toBack }} <!-- 이전 --></span>
				</div>
			</div>
		</div>
		<Empty
			v-if="mode == 'empty'"
			class="full-height"
		>
			<template
				v-slot:body
			>
				<div
					class="text-center character2 pt-140 size-px-14 color-333"
				>
					<div>{{ $language.tv.subject_tv_upload }} <!-- 1분 이내의 동영상을 업로드 하고 게시하세요 --></div>
					<div class="mt-20">
						<button
							class="btn_l btn_fill_blue"
							@click="toFile"
						> <label style="font-size: 16px; vertical-align: unset">{{ $language.shorts.btn_upload }} <!-- 동영상 업로드 --><input v-show="false" type="file" accept="video/*" @change="setFile('video', $event)" ref="file_video" id="file_video" /> </label></button>
					</div>
				</div>
			</template>
		</Empty>
		<div
			v-show="mode != 'empty'"
			class="full-height flex-column"
		>
			<div
				class="bg-white full-height position-relative pt-50 overflow-y-auto"
				style="z-index: 0"
			>
				<div
					class=" overflow-hidden flex-column justify-center"
					:class="mode == 'edit' ? 'edit-video-box radius-20' : 'full-height'"
				>
					<div
						class="justify-space-between gap-10"
						:class="mode == 'edit' ? '' : 'full-height'"
					>
						<div
							class="flex-2 position-relative flex-column justify-center"
							style=" "
							:class="mode == 'edit' ? 'edit-video radius-20' : 'full-height bg-black'"
						>
							<VideoJs
								v-if="item_video.video.src"
								:item="item_video"
								:id="item.bulletin_number + 'edit'"
								class="full-height width-100"
								:controls="is_controls"
								:custom="true"
								:pause="is_cover"
								:is_video="is_video"
								:play="true"

								@onLoadMetadata="onLoadMetadata"
							></VideoJs>

							<div
								v-show="is_cover"
								class="position-absolute-full color-white flex-column justify-center"
								style="z-index: 3"
							>
								<div class="position-relative">
									<div class=" justify-space-between width-100 pa-10 items-center position-absolute " style="left: 0; top: 0; z-index: 3; background-color: #828282">
										커버 이미지
										<button
											class="color-white"
											@click="is_cover = false"
										><v-icon class="color-white">mdi-close-circle</v-icon></button>
									</div>
									<canvas
										id="canvas"
										style="width: 100%; margin-top: 4px"
										class="position-relative"
									></canvas>

									<div
										v-show="false"
									>
										<img :src="post_file_list.post_file_url" class="width-100"/>
									</div>
								</div>
							</div>
						</div>
						<div
							v-show="mode == 'edit'"
							class="flex-1 flex-column justify-end radius-20 text-center"
						>
							<div class="box radius-20 flex-column justify-center" style="min-height: 125px; background-color: black;">
								<img :src="cover_img_url" class="width-100 "/>
							</div>
						</div>
					</div>
				</div>

				<template
					v-if="mode == 'edit'"
				>
				<div
					class="prl-20 "
				>
					<h6 class="size-px-16 font-weight-500 color-333">{{ $language.tv.title_tv_title}} <!-- 제목 --> <span class="color-red">*</span></h6>
					<input
						v-model="post_title"
						class="input-box mt-10"
						:placeholder="$language.tv.txt_tv_title"
					/>
				</div>

				<div
					class="prl-20 mt-20"
				>
					<h6 class="size-px-16 font-weight-500 color-333">{{ $language.tv.title_tv_contents}} <!-- 내용 --> <span class="color-red">*</span></h6>
					<textarea
						v-model="post_contents"
						class="width-100 pa-10 radius-10 mt-15 overflow-y-auto"
						rows="5"
						:placeholder="$language.tv.txt_tv_contents"
						maxlength="300"
					></textarea>
				</div>

				<hr class="bg-gray-light pa-5 mt-20">
				<div class="mt-10">
					<div class=" justify-space-between-in size-px-16">
						<div class="pa-13-20" @click="toEdit">{{ $language.shorts.title_shorts_cover}} <!-- 커버 수정 --> <button ><img :src="require('@/assets/image/icon_arrow_right_gray.svg')" /></button></div>
						<div class="pa-13-20">{{ $language.shorts.title_shorts_option}} <!-- 고급 설정 --> <button ><img :src="require('@/assets/image/icon_arrow_right_gray.svg')" /></button></div>
					</div>
				</div>
				</template>


			</div>

			<div
				v-if="mode == 'cover'"
				class="mt-auto btn_wrap "
			>

				<button
					v-if="is_cover"
					@click="save"
					class="btn btn_fill_blue"
				>커버 이미지 적용</button>
				<button
					v-else
					@click="capture"
					class="btn btn_fill_blue"
				>화면 캡처</button>
			</div>
		</div>
	</div>
</template>

<script>
import Empty from "@/view/Layout/Empty";
import VideoJs from "@/components/VideoJs";

export default {
	name: 'MafiaTvEdit'
	, components: {VideoJs, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'MafiaTvEdit'
				, title: this.$language.tv.title_tv_edit
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, file_max: 1
			, files: []
			, post_file_list: [
				{}
				,{}
			]
			, item_cover: {
				src:''
			}
			, item_board_config: {

			}
			, is_show: false
			, mode: 'edit'
			, post_title: ''
			, post_contents: ''
			, appendix_file_div_code: 'CA01000001'
			, file_upload_code: 'CM00700015'
			, stremg_video_file_list: []
			, item_aws: {

			}
			, item_video: {
				video: {
					src: ''
				}
				, cover: {
					src: ''
				}
			}
			, item: {

			}
			, is_cover: false
			, cover_img_url: ''
			, is_video: false
			, origin_img_index: 1
			, origin_video_index: 2
		}
	}
	, computed: {
		is_controls: function(){
			let t = false
			if(this.mode == 'cover'){
				t = true
			}
			return t
		}
		, upload_files: function(){
			let t = []
			this.post_file_list.filter ( (item) => {
				if(item.post_appendix_file_div_code){
					t.push(item)
				}
			})
			return t
		}
	}
	, methods: {
		setFile: function (type) {

			let file_count = this.files.length + 1
			if (file_count > this.file_max) {
				this.$bus.$emit('notify', {type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			let post_appendix_file_div_code = ''

			let file = this.$refs.file_video.files[0]

			const videourl = URL.createObjectURL(file);
			file.src = videourl
			this.item_video.video = file
			console.log('setFile', file, file.src)

			if (type.indexOf('video') > -1) {
				post_appendix_file_div_code = 'CA01000002'
			} else if (type.indexOf('audio') > -1) {
				post_appendix_file_div_code = 'CA01000003'
			} else {
				post_appendix_file_div_code = 'CA01000001'
			}
			this.upload_files.unshift({type: type, file: file, src: videourl})
			this.files.unshift({type: post_appendix_file_div_code, src: videourl})
			this.stremg_video_file_list[0] = {
				stremg_video_file_indexnumber: 2
				, stremg_video_file_name: file.name
			}

			this.post_file_list[1] = {
				post_appendix_file_div_code: post_appendix_file_div_code
				, post_file_url: file.name
				, post_appendix_file_indexnumber: 2
				, post_appendix_file_change_before_indexnumber: this.origin_video_index
				, index: file_count
			}

			this.mode = 'edit'
			this.is_video = true
			console.log('stremg_video_file_list', this.stremg_video_file_list)
		}
		, toFile: function () {
			this.$bus.$emit('not_pause')
		}

		, capture: function () {
			this.is_cover = true
			let canvas = document.getElementById("canvas");

			let video = document.getElementById('video_' + this.item.bulletin_number + 'edit');

			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			canvas
				.getContext("2d")
				.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

			/** Code to merge image **/
			/** For instance, if I want to merge a play image on center of existing image **/
			const playImage = new Image();
			playImage.src = "path to image asset";
			playImage.onload = () => {
				const startX = video.videoWidth / 2 - playImage.width / 2;
				const startY = video.videoHeight / 2 - playImage.height / 2;
				const ctx = canvas.getContext("2d")
				ctx.filter = 'brightness(50%)'

				canvas.drawImage(playImage, startX, startY, playImage.width, playImage.height);
				canvas.toBlob = (blob) => {
					const img = new Image();
					img.src = window.URL.createObjectUrl(blob);
				};
			};
		}

		, save: function(){
			let canvas = document.getElementById("canvas");
			let file_name = this.$route.params.idx + '_' + this.$route.params.b_id + '_' + Date.now() + '.png'
			console.log(file_name)
			canvas.getContext("2d")
			canvas.filter = 'brightness(50%)'
			canvas.toBlob( (blob) => {
				this.mode = 'edit'
				let file = new File([blob], file_name, { type: 'image/png' });
				console.log(file)
				this.postFile(file)
			})
		}
		, goBack: function(){
			if(this.mode == 'cover'){
				this.mode = 'edit'
			}else if(this.mode == 'edit') {
				this.mode = 'empty'
			}else{
				this.$emit('goBack')
			}
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board_config = result.data
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, toEdit: function(){
			this.mode = 'cover'
		}
		, postSave: async function(){
			if(!this.post_contents){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.shorts.error_shorts_contents})
				return false
			}
			try {
				this.post_file_list.filter( (item) => {
					console.log(item)
					if(!item.post_appendix_file_div_code){
						// throw "첨부파일을 확인하세요"
					}
				})
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.put_cartel_tv
					, data: {
						member_number: this.user.member_number
						, bulletin_number: this.$route.params.bbs_id
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, post_title: this.post_title
						, post_contents: this.post_contents
						, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
						, post_file_list: this.post_file_list
					}
					, type: true
				})

				if (result.success) {
					console.log(result.data)
					if(result.data.stremg_video_file_list.length > 0){
						this.item_aws = result.data
						await this.postAws()
					}
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFile: async function(file){
			try{
				this.$bus.$emit('on', true)
				let login_info = this.$encodeStorage.getSessionAt()

				let domain = process.env.VUE_APP_DOMAIN
				let domain_m = process.env.VUE_APP_DOMAIN_M
				let domain_stg = process.env.VUE_APP_DOMAIN_STG
				let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
				let domain_dev = process.env.VUE_APP_DOMAIN_DEV
				let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
				let dev = process.env.VUE_APP_DEV
				let server = process.env.VUE_APP_SERVER
				let stg = process.env.VUE_APP_STG
				let local = process.env.VUE_APP_LOCAL
				let location = window.location.href

				let baseUrl = ''

// 운영계 API 호출
				if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
					baseUrl = server

// 검증계 API 호출
				}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
					baseUrl = stg

// 개발계 API 호출
				}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
					baseUrl = dev

// 로컬 API 호출
				}else{
					baseUrl = local
				}

				let data = new FormData();

				data.append('member_number', login_info.member_number)
				data.append('file_upload_code', this.file_upload_code)
				data.append('appendix_file_div_code', this.appendix_file_div_code)
				data.append('appendix_file_name',  file)

				const options = {
					method: 'POST'
					, body: data
					, headers: {
						'Accept': 'application/json',
						'A': this.$encodeStorage.getSessionToken(),
						'V': '1.0.0'
						, 'Content-Security-Policy' : 'upgrade-insecure-requests'
					}
				};

				let url = baseUrl + '/' + this.$api_url.api_path.post_file
				await fetch(url, options).then(response => response.json()).then( (result) => {
					console.log(result.d)
					console.log(result.resultinfo)
					if(result.resultinfo.result_div_cd == 'OK'){

						this.post_file_list[0] = {
							post_appendix_file_div_code: this.appendix_file_div_code
							, post_appendix_file_indexnumber: 1
							, post_file_url: result.d.file_url
							, post_appendix_file_change_before_indexnumber: this.origin_img_index
						}

						this.cover_img_url = result.d.file_url
					}else{
						throw result.resultinfo.result_msg
					}
				}).catch( (e) => {
					console.log(e)
					throw e
				});

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
				this.is_cover = false
			}
		}
		, postAws: async function(){
			try{
				this.$bus.$emit('on', true)
				let file = this.item_video;
				console.log('file', file)
				let item = this.item_aws
				const result = await this.$Request({
					method: 'put'
					, url: item.stremg_video_file_list[0].stremg_video_file_url
					, header: {
						// 올리고자하는 동영상의 확장자로 설정 - test-video 파일의 경우 mp4
						"Content-Type": "video/" + this.stremg_video_file_list[0].contents_meta_data.contents_type,
						// "SHTP-02-001 숏플 게시물 등록 요청" - stremg_video_upload_header {key:value} 로 리퀘스트 헤더에 추가
						"x-amz-tagging": item.stremg_video_file_list[0].stremg_video_upload_header['X-Amz-Tagging'][0]
						, "amz-sdk-request" : ["attempt=1; max=3"]
					}
					, data: file
					, type: true
					, is_data: true
					, isResult: true
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.shorts.txt_tv_success})
					this.$bus.$emit('goBack')
				}else{
					throw result.message
				}
			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_tv
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					this.post_title = this.item.post_title
					this.post_contents = this.item.post_contents
					for(let i = 0; i < this.item.post_file_list.length; i++) {
						let file = this.item.post_file_list[i]

						if (file.post_appendix_file_div_code == 'CA01000002') {
							file.post_appendix_file_change_before_indexnumber = 2
							file.src = file.post_appendix_file_org_url
							file.post_file_url = file.post_appendix_file_org_url
							this.item_video.video = file
							this.post_file_list[1] = file
							this.origin_video_index = file.post_appendix_file_indexnumber

						} else {
							file.src = file.post_appendix_file_org_url
							file.post_appendix_file_change_before_indexnumber = 1
							file.post_file_url = file.post_appendix_file_org_url
							this.item_video.cover = file
							this.cover_img_url = file.post_appendix_file_org_url
							this.post_file_list[0] = file
							this.origin_img_index = file.post_appendix_file_indexnumber
						}
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onLoadMetadata: function(e){
			console.log('onLoadMetadata', e.target.videoWidth, e.target.videoHeight, e)
			let width = e.target.videoWidth
			let height = e.target.videoHeight
			let duration = e.target.duration
			let name = this.stremg_video_file_list[0].stremg_video_file_name
			let d = name.split('.')
			let t = d[d.length - 1]
			let data = {
				contents_type: t // "콘텐츠타입", ->required   //png, mp4, mp3 등(현재는 미사용)
				, contents_original_resolu_width: width // ": 콘텐츠원본해상도너비, ->required   //동영상 또는 이미지의 너비(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_resolu_height: height // ": 콘텐츠원본해상도높이, ->required   //동영상 또는 이미지의 높이(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_rolling_direction: height > width ? 9 : 0 // ": 콘텐츠원본회전방향, ->required   //0(회전이 없는 컨텐츠), 1(회전 없음), 3(180도 회전), 6(시계 방향으로 90도 회전), 8(반시계 방향으로 90도 회전)
				, contents_length_seconds: duration + ''// ": 콘텐츠길이초 ->required
			}
			this.$set(this.stremg_video_file_list[0], 'contents_meta_data', data)
			this.$set(this.post_file_list[1], 'contents_meta_data', data)

			setTimeout( () => {
				this.capture()
				setTimeout( () => {
					this.save()
				}, 100)
			}, 300)

			console.log('onLoadMetadata', this.stremg_video_file_list)
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getBoardConfig()
		if(this.$route.params.bbs_id){
			this.getData()
		}
	}

	, watch: {
	}

}
</script>
<style scoped>
textarea{border: 1px solid #dadada;}
textarea::placeholder{color: #bbb;}

.edit-video-box {
	max-height: 400px; padding: 30px 20px 20px 20px
}

.edit-video {
	min-height: 250px; background-color: black
}
</style>